module.exports = [{
      plugin: require('/Users/erik/code/laag/docs/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"gatsbyRemarkPlugins":["gatsby-remark-prismjs","gatsby-remark-embedder"],"remarkPlugins":[null]},
    },{
      plugin: require('/Users/erik/code/laag/docs/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149386814-1","head":true,"anonymize":false},
    },{
      plugin: require('/Users/erik/code/laag/docs/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
